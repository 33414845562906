import React from 'react'
import styled from 'styled-components'

const TopMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 24px;
  background: #fff;
  z-index: 2;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 48px;
  }
`
const BottomMask = styled(TopMask)`
  top: initial;
  bottom: 0;
  right: initial;
  left: 0;
`

const SteppedImageMask = () => {
  return (
    <>
      <TopMask />
      <BottomMask />
    </>
  )
}

export default SteppedImageMask
