import React, { useState, useEffect, useContext } from 'react'
import styled, { keyframes } from 'styled-components'

import { StateContext } from '../context/GlobalStateContext'
import { ReactComponent as LongDriveSvg } from '../images/home-long-drive.svg'
import { ReactComponent as GoodworkSvg } from '../images/home-good-work.svg'

// styles
// ==========================================================================================
const animationDelay = 0.5
// const easeInOutExpo = 'cubic-bezier(1, 0, 0, 1)'
const easeInOutQuart = 'cubic-bezier(0.77, 0, 0.175, 1)'
// const easeInOutCubic = 'cubic-bezier(0.645, 0.045, 0.355, 1)'
const enterFrames = keyframes`
  0% {
    opacity:0;
    transform:translate3d(0, 36px, 0);
  }
  100% {
    opacity: 1;
    transform:translate3d(0, 0, 0);
  }`
const Card = styled.div`
  animation: ${enterFrames} 1s ${easeInOutQuart};
  animation-fill-mode: both;
  animation-play-state: paused;
  animation-delay: ${animationDelay}s;
`
const Card2 = styled(Card)`
  margin-top: 24px;
  animation-delay: ${animationDelay + 1}s;
`
const CardInner = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  padding: 12px 16px;
  overflow: hidden;

  p {
    margin-top: 0.5em;
    font-size: 14px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 30px 37px;

    p {
      font-size: 16px;
    }
  }
`
const Emphasis = styled.em`
  color: ${({ theme }) => theme.colors.primary};
  font-style: normal;
`
const Wrapper = styled.div`
  position: absolute;
  right: 10%;
  bottom: 18px;
  padding-left: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    right: 15%;
    bottom: 36px;
  }
  svg {
    display: block;
    height: auto;
    max-width: 100%;
    margin-left: auto;
  }
  ${Card} {
    animation-play-state: ${({ reveal }) => (reveal ? 'running' : 'paused')};
  }
  ${Card2} {
    animation-play-state: ${({ reveal }) => (reveal ? 'running' : 'paused')};
  }
`

// component
// ==========================================================================================
const FeatureAnimation3 = () => {
  const [reveal, setReveal] = useState(false)
  const [{ currentHomeFeature }] = useContext(StateContext)

  useEffect(() => {
    if (currentHomeFeature === 2) {
      setReveal(true)
    }
  }, [currentHomeFeature])

  return (
    <Wrapper reveal={reveal} aria-hidden>
      <Card>
        <CardInner>
          <h5>
            <Emphasis>Hello Karen</Emphasis>. You have had an unusually long car
            drive…
          </h5>
          <p>
            Check your blood glucose to learn more about the impact of this
            activity.
          </p>
        </CardInner>
        <LongDriveSvg />
      </Card>
      <Card2>
        <CardInner>
          <h5>Keep up the good work</h5>
          <p>
            <Emphasis>127 mg/dl</Emphasis> allows you to safely keep driving.
            Don’t forget to take a short break every hour.
          </p>
        </CardInner>
        <GoodworkSvg />
      </Card2>
    </Wrapper>
  )
}

export default FeatureAnimation3
