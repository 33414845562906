import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import Image from './Image'
import { asText } from '../utils/prismic'

// styles
// ==========================================================================================
const Header = styled.div`
  margin-bottom: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 48px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 80px;
  }
`
const Award = styled.div`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  position: relative;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: block !important;
    margin-bottom: 64px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 72px;
  }
`
const ImageWrapper = styled.div`
  position: relative;
  width: 75%;
  margin-bottom: 24px;
  padding-bottom: 35%;

  & > * {
    position: absolute !important;
    height: 100%;
    width: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain !important;
    object-position: 0 center !important;
  }
`

// component
// ==========================================================================================
const PartnersList = ({ partnersSectionTitle, partners, showAll }) => {
  const groupedItems = []

  for (let i = 0; i < partners.length; i += 3) {
    groupedItems.push(partners.slice(i, i + 3))
  }

  return (
    <>
      <Row>
        <Col xs={7} xsOffset={1}>
          <Header>
            <h2>{asText(partnersSectionTitle)} </h2>
          </Header>
        </Col>
      </Row>
      <Row>
        {partners.map((item, i) => {
          const xsOffset = i % 2 === 0 ? 1 : 0
          let lgOffset = i % 3 === 0 ? 1 : 0
          // every second row of 3 has a different offset
          if ((i + 3) % 6 === 0) {
            lgOffset = 3
          }
          return (
            <Col
              xs={4}
              xsOffset={xsOffset}
              md={2}
              mdOffset={1}
              lg={2}
              lgOffset={lgOffset}
              key={i}
            >
              <Award isHidden={!showAll && i >= 2}>
                <ImageWrapper>
                  <Image image={item.logo} {...item.logoSharp} />
                </ImageWrapper>
                {item.name && <h5>{item.name}</h5>}
              </Award>
            </Col>
          )
        })}
      </Row>
    </>
  )
}

export default PartnersList
