import React from 'react'
// import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

import Awards from '../components/Awards'

// data
// ==========================================================================================
const query = graphql`
  query AwardsQuery {
    prismic {
      data: awards(uid: "awards", lang: "en-gb") {
        awardsSectionTitle: awards_section_title
        awards {
          award
          competition
          year
          logo
          logoSharp {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          link {
            ... on PRISMIC__ExternalLink {
              target
              _linkType
              url
            }
          }
        }
      }
    }
  }
`

// static query
// ==========================================================================================
const AwardsWithQuery = props => (
  <StaticQuery
    // waiting for bug to be resolved https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    query={process.env.GATSBY_ENV === 'development' ? query.toString() : query}
    render={withPreview(
      ({ prismic: { data } }) => (
        <Awards {...data} {...props} />
      ),
      query
    )}
  />
)

export default AwardsWithQuery
