import React, { useState, useCallback } from 'react'
import styled from 'styled-components'

// styles
// ==========================================================================================
const SeeMoreButton = styled.button`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  margin: 8px auto 0;
  padding: 0;
  border: none;
  background: none;
  appearance: none;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.primary};
  border-bottom: 1px solid;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`

// component
// ==========================================================================================
const ShowAllMobile = ({ children }) => {
  const [showAll, setShowAll] = useState(false)
  const handleSeeMore = useCallback(() => {
    setShowAll(true)
  })

  const childrenWithProps = React.cloneElement(children, { showAll })

  return (
    <>
      {childrenWithProps}
      <SeeMoreButton
        onClick={handleSeeMore}
        hidden={showAll}
        aria-hidden={showAll}
      >
        Show all
      </SeeMoreButton>
    </>
  )
}

export default ShowAllMobile
