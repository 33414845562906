import React, { useRef, useCallback, useEffect, useState } from 'react'

import Hero from './Hero'
import { breakpoints } from '../styles/vars'

// styles
// ==========================================================================================
// component
// ==========================================================================================
const HomeHero = props => {
  const timer = useRef(null)
  const [hasUserInteracted, setHasUserInteracted] = useState(false)
  const handleScroll = useCallback(() => {
    // do not show video on smaller screens
    if (window.innerWidth >= breakpoints.md) {
      setHasUserInteracted(true)
    }
    window.removeEventListener('scroll', handleScroll)
  })
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  useEffect(() => {
    timer.current = setTimeout(() => {
      handleScroll()
    }, 3000)

    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  return <Hero {...props} triggerVideo={hasUserInteracted} />
}

export default HomeHero
