import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Content from '../components/Content'
import Layout from '../components/Layout'
import HomeHero from '../components/HomeHero'
import FeatureList from '../components/FeatureList'
// import Special from '../components/Special'
import Highlight from '../components/Highlight'
import Awards from '../containers/Awards'
import Partners from '../containers/Partners'
import Separator from '../components/Separator'

export const query = graphql`
  query HomeQuery {
    prismic {
      data: home(uid: "home", lang: "en-gb") {
        metaTitle: meta_title
        metaDescription: meta_description
        metaImage: meta_image
        heroLabel: hero_label
        heroTitle: hero_title
        heroBody: hero_body
        heroImage: hero_image
        heroImageSharp: hero_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        features {
          title
          body
          id
          icon
          image
          imageSharp {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        heroVideo: video {
          ... on PRISMIC__FileLink {
            url
          }
        }
        highlightCtaLabel: highlight_cta_label
        highlightCta: highlight_cta {
          _linkType
          ... on PRISMIC__ExternalLink {
            url
          }
          ... on PRISMIC_Home {
            meta: _meta {
              id
              uid
              type
            }
          }
          ... on PRISMIC_About {
            meta: _meta {
              id
              uid
              type
            }
          }
          ... on PRISMIC_Product {
            meta: _meta {
              id
              uid
              type
            }
          }
          ... on PRISMIC_Contact {
            meta: _meta {
              id
              uid
              type
            }
          }
          ... on PRISMIC_Generic {
            meta: _meta {
              id
              uid
              type
            }
          }
          ... on PRISMIC_Case_study {
            meta: _meta {
              id
              uid
              type
            }
          }
          ... on PRISMIC__Linkable {
            _linkType
          }
        }
        highlightTitle: highlight_title
        highlightBody: highlight_body
        highlight_image
        highlightImageSharp: highlight_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        highlightCtaLabel: highlight_cta_label
        highlightCta: highlight_cta {
          _linkType
          ... on PRISMIC__ExternalLink {
            url
          }
          ... on PRISMIC__Document {
            meta: _meta {
              id
              uid
              type
            }
          }
        }
        specialTitle: special_title
        specialBody: special_body
        specialImage: special_image
        specialImageSharp: special_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const PageContainer = ({
  data: {
    prismic: { data },
  },
}) => {
  return (
    <Layout {...data}>
      <HomeHero {...data} />
      <Separator />
      <Content>
        <FeatureList {...data} />
        <Separator />
        <Separator />
        {/* <Special {...data} /> */}
        <Highlight {...data} />
        <Separator />
        <Awards />
        <Separator />
        <Partners />
      </Content>
    </Layout>
  )
}

// PageContainer.propTypes = {
//   data: PropTypes.shape({
//     prismic: PropTypes.shape({
//       data: PropTypes.shape({}).isRequired,
//     }).isRequired,
//   }).isRequired,
// }

export default PageContainer
