import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import { linkResolver } from '../utils/linkResolver'

// styles
// ==========================================================================================
const easeInOutQuart = 'cubic-bezier(0.77, 0, 0.175, 1)'
const Wrapper = styled.div`
  a {
    position: relative;
    display: inline-block;
    padding: 16px 40px;
    border: none;
    overflow: hidden;
    background: ${({ theme }) => theme.colors.gray10};
    font-family: ${({ theme }) => theme.fonts.feature};
    font-size: 18px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${({ theme }) => theme.colors.primary};
      transform: translate3d(-95%, 0%, 0);
      transition: transform 0.85s ${easeInOutQuart};
    }
    span {
      position: relative;
      color: #fff;
      mix-blend-mode: difference;
      z-index: 1;
    }
    &:hover {
      background: ${({ theme }) => theme.colors.gray10};
      &::before {
        transform: translate3d(0, 0, 0);
      }
      &::after {
        transform: translate3d(90%, 20%, 0);
      }
    }
  }
`

// component
// ==========================================================================================
const ButtonLink = ({ url, meta, target, children, title }) => {
  const rel = target === '_blank' ? 'noopener noreferrer' : ''

  if (!url && !meta) {
    return null
  }

  if (meta) {
    return (
      <Wrapper>
        <GatsbyLink to={linkResolver(meta)} title={title}>
          <span>{children}</span>
        </GatsbyLink>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <a href={url} target={target} rel={rel} title={title}>
        <span>{children}</span>
      </a>
    </Wrapper>
  )
}

export default ButtonLink
