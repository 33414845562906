import React, { useRef, useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// styles
// -----------------------------------------------------------------------
// const easeInOutQuart = 'cubic-bezier(0.77, 0, 0.175, 1)'
// const easeOutQuart = 'cubic-bezier(0.165, 0.84, 0.44, 1)'
const easeOutCirc = 'cubic-bezier(0.075, 0.82, 0.165, 1)'
const easeOutQuad = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)'
const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  /* opacity: ${({ revealed }) => (revealed ? 1 : 0)}; */
  transition: opacity 1s;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 2;
    transform: translate3d(0, ${({ revealed }) => (revealed ? '100%' : 0)}, 0);
    /* transform: translate3d(0, 50%, 0); */
    transition: transform 1s ${easeOutCirc};
  }
`
const Content = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  transform: scale(${({ revealed }) => (revealed ? 1 : 1.1)});
  transition: transform 1.2s ${easeOutQuad};
`
// -----------------------------------------------------------------------

// component
// -----------------------------------------------------------------------
const RevealBoilerplate = ({ children, className }) => {
  const element = useRef()
  const [isRevealed, setIsRevealed] = useState(false)
  const observer = useRef(null)

  const handleIntersection = useCallback(entries => {
    if (entries[0].isIntersecting) {
      setIsRevealed(true)
      observer.current.unobserve(element.current)
    }
  }, [])

  useEffect(() => {
    const currentElement = element.current
    // use the IntersectionObserver API to check when the canvas is inside the viewport
    observer.current = new IntersectionObserver(handleIntersection, {
      // percentage of the element that has to be onscreen before triggering
      threshold: 0.5,
    })
    // const obsrvr = observer.current
    observer.current.observe(currentElement)
    // cleanup listeners on unmount
    return () => {
      observer.current.unobserve(currentElement)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper ref={element} revealed={isRevealed} className={className}>
      <Content revealed={isRevealed}>{children}</Content>
    </Wrapper>
  )
}

RevealBoilerplate.propTypes = {
  // Number of window heights during which the scrolling will happen. A higher number is a slower transititon
  children: PropTypes.node.isRequired,
}

export default RevealBoilerplate
