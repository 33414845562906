import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import ImageComponent from './Image'
import ImageRevealerComponent from './ImageRevealer'
import Wysiwyg from './Wysiwyg'
import FeatureAnimation1 from './FeatureAnimation1'
import FeatureAnimation2 from './FeatureAnimation2'
import FeatureAnimation3 from './FeatureAnimation3'
import Title from './Title'

const animations = {
  first: FeatureAnimation1,
  second: FeatureAnimation2,
  third: FeatureAnimation3,
}

// styles
// ==========================================================================================
const Wrapper = styled.div`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
  p {
    margin-top: 8px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 80px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 184px;
  }
`
const Heading = styled(Title)`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 16px;
  }
`
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 24px;
  padding-bottom: 150%;
  background: ${({ theme }) => theme.colors.gray10};
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding-bottom: 80%;
  }
`
const ImageRevealer = styled(ImageRevealerComponent)`
  position: absolute;
  height: 100%;
  width: 100%;
`
const Image = styled(ImageComponent)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const Icon = styled.div`
  width: 45px;
  height: 45px;
  margin-bottom: 16px;
  padding: 6px;
  background: ${({ theme }) => theme.colors.gray10};
  border-radius: 6px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

// component
// ==========================================================================================
const FeatureItem = forwardRef(
  ({ title, body, imageSharp, icon, id, index }, ref) => {
    const isEven = index % 2 !== 0
    return (
      <Wrapper data-index={index} ref={ref}>
        <Row middle="xs" reverse={isEven}>
          {isEven && <Col xs={1} />}
          <Col xs={8} xsOffset={1} md={2} mdOffset={1}>
            {icon && (
              <Icon>
                <ImageComponent image={icon} />
              </Icon>
            )}
            <Heading tag="h4" content={title} />
            <Wysiwyg content={body} />
          </Col>
          <Col xs={10} md={6} mdOffset={isEven ? 0 : 1}>
            <ImageWrapper>
              <ImageRevealer>
                <Image {...imageSharp} />
              </ImageRevealer>
              {animations[id] && animations[id].call()}
            </ImageWrapper>
          </Col>
        </Row>
      </Wrapper>
    )
  }
)

export default FeatureItem
