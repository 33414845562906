import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import Image from './Image'
import Link from './Link'
import { asText } from '../utils/prismic'

// styles
// ==========================================================================================
const Header = styled.div`
  margin-bottom: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 48px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 80px;
  }
`
const Award = styled.div`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  position: relative;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: block !important;
    margin-bottom: 64px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 72px;
  }
`

const AwardLink = styled(Link)`
  background: transparent;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  position: relative;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: block !important;
    margin-bottom: 64px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 72px;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 75%;
  margin-bottom: 24px;
  padding-bottom: 35%;

  & > * {
    position: absolute !important;
    height: 100%;
    width: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain !important;
    object-position: 0 center !important;
  }
`

// component
// ==========================================================================================
const AwardsList = ({ awardsSectionTitle, awards, showAll }) => {
  const groupedAwards = []

  for (let i = 0; i < awards.length; i += 3) {
    groupedAwards.push(awards.slice(i, i + 3))
  }

  return (
    <>
      <Row>
        <Col xs={7} xsOffset={1}>
          <Header>
            <h2>{asText(awardsSectionTitle)} </h2>
          </Header>
        </Col>
      </Row>
      <Row>
        {awards.map((item, i) => {
          const xsOffset = i % 2 === 0 ? 1 : 0
          let lgOffset = i % 3 === 0 ? 1 : 0
          // every second row of 3 has a different offset
          if ((i + 3) % 6 === 0) {
            lgOffset = 3
          }
          return (
            <Col
              xs={4}
              xsOffset={xsOffset}
              md={2}
              mdOffset={1}
              lg={2}
              lgOffset={lgOffset}
              key={`${item.competition}${item.year}`}
            >
              {item.link ? (
                <AwardLink link={item.link} isHidden={!showAll && i >= 2}>
                  <ImageWrapper>
                    <Image image={item.logo} />
                  </ImageWrapper>
                  <h5>{item.award}</h5>
                  {item.competition}
                  {item.year && (
                    <>
                      <br />
                      {item.year}
                    </>
                  )}
                </AwardLink>
              ) : (
                <Award isHidden={!showAll && i >= 2}>
                  <ImageWrapper>
                    <Image image={item.logo} {...item.logoSharp} />
                  </ImageWrapper>
                  <h5>{item.award}</h5>
                  {item.competition}
                  {item.year && (
                    <>
                      <br />
                      {item.year}
                    </>
                  )}
                </Award>
              )}
            </Col>
          )
        })}
      </Row>
    </>
  )
}

export default AwardsList
