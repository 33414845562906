import React from 'react'

import ShowAllMobile from './ShowAllMobile'
import AwardsList from './AwardsList'

// component
// ==========================================================================================
const Awards = props => {
  return (
    <section>
      <ShowAllMobile>
        <AwardsList {...props} />
      </ShowAllMobile>
    </section>
  )
}

export default Awards
