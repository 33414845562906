import React from 'react'
// import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

import Partners from '../components/Partners'

// data
// ==========================================================================================
const query = graphql`
  query PartnersQuery {
    prismic {
      data: partners(uid: "partners", lang: "en-gb") {
        partnersSectionTitle: partners_section_title
        partners {
          name
          logo
          logoSharp {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

// static query
// ==========================================================================================
const PartnersWithQuery = props => (
  <StaticQuery
    // waiting for bug to be resolved https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    query={process.env.GATSBY_ENV === 'development' ? query.toString() : query}
    render={withPreview(
      ({ prismic: { data } }) => (
        <Partners {...data} {...props} />
      ),
      query
    )}
  />
)

export default PartnersWithQuery
