import React from 'react'

import ShowAllMobile from './ShowAllMobile'
import PartnersList from './PartnersList'

// component
// ==========================================================================================
const Partners = props => {
  return (
    <section>
      <ShowAllMobile>
        <PartnersList {...props} />
      </ShowAllMobile>
    </section>
  )
}

export default Partners
