import React, { useState, useEffect, useContext } from 'react'
import styled, { keyframes, css } from 'styled-components'

import { StateContext } from '../context/GlobalStateContext'
import { ReactComponent as AnalysisSvg } from '../images/home-analysis.svg'
import { ReactComponent as ChartSvg } from '../images/home-chart.svg'

// animation setup
// ==========================================================================================
function createLoaderAnimation() {
  const styles = []
  for (let i = 0; i < 6; i++) {
    styles.push(`
       &.path${i + 1} {
         animation-delay: ${(i + 1) * 70}ms;
       }
     `)
  }

  return css`
    ${styles.join('')}
  `
}
const animationDelay = 0.5
// const easeInOutExpo = 'cubic-bezier(1, 0, 0, 1)'
const easeInOutQuart = 'cubic-bezier(0.77, 0, 0.175, 1)'
// const easeInOutCubic = 'cubic-bezier(0.645, 0.045, 0.355, 1)'
const analysisFrames = keyframes`
  0% {
    opacity:0;
    transform:translate3d(0, 150%, 0);
  }
  30% {
    opacity: 1;
    transform:translate3d(0, 100%, 0);
  }
  70% {
    opacity: 1;
    transform:translate3d(0, 100%, 0);
  }
  100% {
    opacity:0;
    transform:translate3d(0, 50%, 0);
  }`
const chartFrames = keyframes`
  0% {
    opacity:0;
    transform:translate3d(0, 36px, 0);
  }
  100% {
    opacity: 1;
    transform:translate3d(0, 0, 0);
  }`
const graphFrames = keyframes`
  from {
    stroke-dashoffset: 600;
  }
  to {
    stroke-dashoffset: 0;
  }`
const loaderFrames = keyframes`
  from {
    fill: ${({ theme }) => theme.colors.primary};
  }
  to {
    fill: #fff;
  }
`

// styles
// ==========================================================================================

const Analyser = styled(AnalysisSvg)`
  animation: ${analysisFrames} 2.5s ${easeInOutQuart};
  animation-fill-mode: both;
  animation-play-state: paused;
  animation-delay: ${animationDelay}s;

  .circle path {
    animation: ${loaderFrames} 420ms 5;

    ${createLoaderAnimation()}
  }
`
const Chart = styled(ChartSvg)`
  /* margin-bottom: 36px; */
  animation: ${chartFrames} 0.7s ${easeInOutQuart};
  animation-fill-mode: both;
  animation-play-state: paused;
  animation-delay: ${animationDelay + 1.95}s;
  opacity: 0;

  .graph {
    stroke-dasharray: 600;
    stroke-dashoffset: 600;
    animation: ${graphFrames} 2s linear;
    animation-fill-mode: both;
    animation-play-state: paused;
    animation-delay: ${animationDelay + 1.95}s;
  }
`
const Wrapper = styled.div`
  position: absolute;
  right: 10%;
  bottom: 18px;
  padding-left: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    right: 15%;
    bottom: 36px;
  }
  svg {
    display: block;
    height: auto;
    max-width: 100%;
    margin-left: auto;
    /* margin-left: 10%; */
  }
  ${Analyser} {
    animation-play-state: ${({ reveal }) => (reveal ? 'running' : 'paused')};
  }
  ${Chart} {
    animation-play-state: ${({ reveal }) => (reveal ? 'running' : 'paused')};
    opacity: 0;

    .graph {
      animation-play-state: ${({ reveal }) => (reveal ? 'running' : 'paused')};
    }
  }
`

// component
// ==========================================================================================
const FeatureAnimation1 = () => {
  const [reveal, setReveal] = useState(false)
  const [{ currentHomeFeature }] = useContext(StateContext)

  useEffect(() => {
    if (currentHomeFeature === 0) {
      setReveal(true)
    }
  }, [currentHomeFeature])

  return (
    <Wrapper reveal={reveal}>
      <Analyser />
      <Chart />
    </Wrapper>
  )
}

export default FeatureAnimation1
