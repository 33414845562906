import React, {
  useRef,
  createRef,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import styled from 'styled-components'

import { StateContext } from '../context/GlobalStateContext'
import FeatureItem from './FeatureItem'

// styles
// ==========================================================================================
const Wrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
  }
`

// component
// ==========================================================================================
const FeatureList = ({ features }) => {
  if (!features || !features.length) {
    return null
  }
  const [, dispatch] = useContext(StateContext)
  const refs = useRef(features.map(() => createRef(null)))
  const options = { threshold: [0.5] }
  const intersectionCallback = useCallback((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const { index } = entry.target.dataset

        dispatch({
          type: 'revealHomeFeature',
          updates: { currentHomeFeature: Number(index) },
        })
        observer.unobserve(entry.target)
      }
    })
  })
  useEffect(() => {
    const observer = new IntersectionObserver(intersectionCallback, options)

    refs.current.forEach(({ current }) => {
      observer.observe(current)
    })
    return () => {
      refs.current.forEach(({ current }) => {
        observer.unobserve(current)
      })
    }
  }, [])

  return (
    <Wrapper>
      {features.map((feature, i) => (
        <FeatureItem
          {...feature}
          index={i}
          key={feature.id}
          ref={refs.current[i]}
        />
      ))}
    </Wrapper>
  )
}

export default FeatureList
