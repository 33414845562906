import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import ImageComponent from './Image'
import ImageRevealerComponent from './ImageRevealer'
import Wysiwyg from './Wysiwyg'
import Title from './Title'
import SteppedImageMask from './SteppedImageMask'
import ButtonLink from './ButtonLink'

// styles
// ==========================================================================================
const Wrapper = styled.div``
const Heading = styled(Title)`
  margin-bottom: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 24px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 36px;
  }
`
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 80%;
  margin-bottom: 32px;
  background: ${({ theme }) => theme.colors.gray10};
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    /* padding-bottom: 60%; */
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
  }
`
const ImageRevealer = styled(ImageRevealerComponent)`
  position: absolute;
  width: 100%;
  height: 100%;
`
const Image = styled(ImageComponent)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`
const BodyWrapper = styled.div`
  margin-bottom: 24px;
`

// component validation
// ==========================================================================================
export const isHighlightDataValid = ({
  highlightImageSharp,
  highlightBody,
} = {}) => Boolean(highlightImageSharp && highlightBody)

// component
// ==========================================================================================
const Highlight = ({
  highlightTitle,
  highlightBody,
  highlightImageSharp,
  highlightCtaLabel,
  highlightCta,
}) => {
  if (
    !isHighlightDataValid({
      highlightImageSharp,
      highlightBody,
    })
  ) {
    return null
  }

  return (
    <Wrapper>
      {highlightTitle && (
        <Row>
          <Col xs={8} xsOffset={1} md={7}>
            <Heading tag="h2" content={highlightTitle} />
          </Col>
        </Row>
      )}
      <Row middle="xs">
        <Col xs={8} xsOffset={1} md={2} mdOffset={1}>
          <BodyWrapper>
            <Wysiwyg content={highlightBody} />
          </BodyWrapper>
          <ButtonLink {...highlightCta} title="Learn more">
            {highlightCtaLabel}
          </ButtonLink>
        </Col>
        <Col xs={10} md={6} mdOffset={1}>
          <ImageWrapper>
            <SteppedImageMask />
            <ImageRevealer>
              <Image {...highlightImageSharp} />
            </ImageRevealer>
          </ImageWrapper>
        </Col>
      </Row>
    </Wrapper>
  )
}

export default Highlight
