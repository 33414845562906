import React, { useState, useEffect, useContext } from 'react'
import styled, { keyframes } from 'styled-components'

import { StateContext } from '../context/GlobalStateContext'
import { ReactComponent as DataSvg } from '../images/home-data.svg'

// styles
// ==========================================================================================
const animationDelay = 0.5
// const easeInOutExpo = 'cubic-bezier(1, 0, 0, 1)'
const easeInOutQuart = 'cubic-bezier(0.77, 0, 0.175, 1)'
// const easeInOutCubic = 'cubic-bezier(0.645, 0.045, 0.355, 1)'
const enterFrames = keyframes`
  0% {
    opacity:0;
    transform:translate3d(0, 36px, 0);
  }
  100% {
    opacity: 1;
    transform:translate3d(0, 0, 0);
  }`
const scrollFrames = keyframes`
  0% {
    transform:translate3d(0, 0, 0);
  }
  100% {
    transform:translate3d(0,  -101%, 0);
  }`

const Card = styled.div`
  animation: ${enterFrames} 1s ${easeInOutQuart};
  animation-fill-mode: both;
  animation-play-state: paused;
  animation-delay: ${animationDelay}s;
`
const Dataset = styled.div`
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 5%;
  width: 25%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.3em;

  p {
    margin-top: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  span {
    display: block;
    margin-top: 0.5em;
    color: ${({ theme }) => theme.colors.primary};
  }
`
const DatasetInner = styled.div`
  position: absolute;
  top: 100%;
  animation: ${scrollFrames} 6s ease-out;
  animation-fill-mode: both;
  animation-play-state: paused;
  animation-delay: ${animationDelay + 0.55}s;
  transform: translate3d(0, 90%, 0);
`
const Wrapper = styled.div`
  position: absolute;
  right: 10%;
  bottom: 18px;
  padding-left: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    right: 15%;
    bottom: 36px;
  }
  svg {
    display: block;
    height: auto;
    max-width: 100%;
    margin-left: auto;
    /* margin-left: 10%; */
  }
  ${Card} {
    animation-play-state: ${({ reveal }) => (reveal ? 'running' : 'paused')};
  }
  ${DatasetInner} {
    animation-play-state: ${({ reveal }) => (reveal ? 'running' : 'paused')};
  }
`

// component
// ==========================================================================================
const FeatureAnimation2 = () => {
  const [reveal, setReveal] = useState(false)
  const [globalState] = useContext(StateContext)
  const { currentHomeFeature } = globalState

  useEffect(() => {
    if (currentHomeFeature === 1) {
      setReveal(true)
    }
  }, [currentHomeFeature])

  return (
    <Wrapper reveal={reveal} aria-hidden>
      <Card>
        <Dataset>
          <DatasetInner>
            <p>
              Time stamp: <span>6:24 am</span>
            </p>
            <p>
              Blood Glucose: <span>139 mg/dL</span>
            </p>
            <p>
              Target range: <span>70 – 180 </span>
            </p>
            <p>
              Glucose Average: <span>141 mg/dL</span>
            </p>
            <p>
              Carbs: <span>326 g</span>
            </p>
            <p>
              Rapid-Acting Insulin: <span>39.3 u</span>
            </p>
            <p>
              Likelihood of low Glucose: <span>73%</span>
            </p>
          </DatasetInner>
        </Dataset>
        <DataSvg />
      </Card>
    </Wrapper>
  )
}

export default FeatureAnimation2
